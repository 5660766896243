import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/seo"

const Common = ({ data: { page } }) => {
  const [scrollAmount, setScrollAmount] = useState(0)
  const minScrollAmount = 500

  useEffect(() => {
    const KEY_CODES = {
      PGUP: 33,
      PGDOWN: 34,
      END: 35,
      HOME: 36,
    }
    const scrollAmount = 500

    const handler = event => {
      if (event.keyCode === KEY_CODES.HOME) {
        window.document.querySelector("main").scrollTop = 0
      }
      if (event.keyCode === KEY_CODES.END) {
        window.document.querySelector(
          "main"
        ).scrollTop = window.document.querySelector("main").scrollHeight
      }
      if (event.keyCode === KEY_CODES.PGUP) {
        window.document.querySelector("main").scrollTop -= scrollAmount
      }
      if (event.keyCode === KEY_CODES.PGDOWN) {
        window.document.querySelector("main").scrollTop += scrollAmount
      }
    }
    window.addEventListener("keydown", handler)

    const scrollElement = window.document.querySelector("main")

    if (scrollElement) {
      const scrollHandler = event => {
        setScrollAmount(event.target.scrollTop)
      }
      scrollElement.addEventListener("scroll", scrollHandler)
    }
  }, [])

  let featuredImgFluid = page.frontmatter.featuredImage
    ? page.frontmatter.featuredImage.childImageSharp.fluid
    : null

  return (
    <Layout>
      <SEO title={page.frontmatter.title} />
      <div
        role="button"
        tabIndex="-1"
        className={`invisible cursor-pointer fixed bottom-4 right-8 z-10 flex flex-col items-center ${
          scrollAmount >= minScrollAmount ? "xl:visible" : "invisible"
        }`}
        onClick={() => {
          document.querySelector("main").scrollTop = 0
        }}
      >
        <StaticImage
          className="h-10 w-10"
          src="../images/icon-up-arrow.svg"
          title="Back to top"
          alt="Back to top"
        />
        <span className="text-color-primary">Back to top</span>
      </div>

      <div className="relative w-full h-36 bg-color-secondary flex items-center justify-center flex-col py-44 mb-2">
        <h2 className="font-molot  text-color-primary text-5xl md:text-4xl lg:text-5xl xl:text-6xl text-center">
          {page.frontmatter.title}
        </h2>
        <h4 className="font-display text-white p-5 text-xl text-center">
          By {page.frontmatter.author}
          <br />
          {page.frontmatter.date}
        </h4>
      </div>
      <Link
        className="mx-auto text-2xl flex flex-col items-center font-bold text-color-primary hover:text-color-primary-lightened p-5"
        to="/"
      >
        <StaticImage
          className="mt-1 h-5 items-center w-6 flex flex-row justify-center"
          src="../images/icon-home.svg"
          alt="Github icon"
        />
        <span className="h-5">Back to posts</span>
      </Link>
      <div className="w-full p-5 max-w-4xl mx-auto">
        {featuredImgFluid ? <Img fluid={featuredImgFluid} /> : null}
      </div>
      <div
        className="markdown p-5"
        dangerouslySetInnerHTML={{ __html: page.html }}
      />
    </Layout>
  )
}

export default Common

export const query = graphql`
  query Common($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        templateKey
        path
        date(formatString: "DD MMMM, YYYY")
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
        author
      }
      fields {
        slug
      }
      html
    }
  }
`
